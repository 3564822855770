<template>
  <div class="help">
    <div class="help_title">
      More frequently asked Questions
    </div>
    <div class="quest_box">
      <div class="quest_title">
        How are hosting fees deducted?
      </div>
      <div class="quest_content">
        Hosting fees are automatically deducted from the daily earnings. The
        backend displays the actual credited earnings, which are net of hosting
        fees.
      </div>
      <div class="quest_title">
        When are daily earnings and electricity fees deducted?
      </div>
      <div class="quest_content">
        Daily earnings and electricity fees are deducted at 18:00 PM (GMT+8)
        each day.
      </div>
      <div class="quest_title">
        What about withdrawal times and fees?
      </div>
      <div class="quest_content">
        1. Withdrawals are processed on a T+0 basis. Withdrawals made before
        17:00 (GMT+8) on working days will be credited the same day, while
        withdrawals made after 17:00 will be credited the next working day.
        Earnings are distributed as usual during holidays.
        <br />
        2. The minimum withdrawal amount is 0.00054 BTC per transaction, with a
        fee of 0.00011 BTC per transaction (fees may be adjusted based on BTC
        network congestion to ensure withdrawal efficiency).
      </div>
      <div class="quest_title">
        What happens if the electricity fee balance is insufficient?
      </div>
      <div class="quest_content">
        If the pre-deposited electricity fee is insufficient to cover two days
        of required fees, the mining machine will stop operating and producing.
        Once the pre-deposited amount is sufficient to cover at least two days
        of electricity fees, the machine will resume operations by 17:00 PM
        (GMT+8) the following day. Users are responsible for monitoring their
        electricity fee balance to avoid disruptions in production and
        unnecessary losses.
      </div>
      <div class="quest_title">
        Can users transfer or withdraw their mining machines?
      </div>
      <div class="quest_content">
        1. Users who purchase and host mining machines through the platform can
        transfer their machines to other registered users on the platform if
        they no longer wish to continue the cooperation. The transfer price will
        be negotiated between the buyer and seller, and the platform’s customer
        service will assist with the transfer process. Please consult your sales
        representative for details.
        <br />
        2. Users can withdraw their mining machines. The platform supports using
        partner logistics for transportation, with related costs borne by the
        user. Please consult your sales representative for more details.
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "my",
  data() {
    return {
      coinNumber: "",
      coinType: "ETH",
      filCoinType: "FIL",
      coinCny: "",
      authStatus: null,
      isShowExitView: false,
      rmbBalance: "",
      filBalance: "",
      filRmb: "",
      uid: "",
    }
  },
  created() {
    this.getMeInforMation()
  },
  components: {},
  mounted() {},
  methods: {
    go(url) {
      this.$router.push(url)
    },
    // 获取我的个人信息
    getMeInforMation() {
      this.$request
        .post("/user/userInfo")
        .then(
          (res) => {
            if (res.code == 200) {
              // this.coinNumber = res.data.balance
              // this.coinCny = res.data.rmb
              // this.authStatus = res.data.authStatus
              // this.filBalance = res.data.filBalance
              // this.filRmb = res.data.filRmb
              // this.rmbBalance = res.data.rmbBalance
              this.uid = res.data.uid
            } else {
              // this.$toast(res.msg)
              if (res.code == 1018) {
                this.$router.push("/login")
              }
            }
            // 执行成功的回调函数
          },
          (error) => {
            console.log(error)
            // 执行失败的回调函数
          }
        )
        .catch(() => {
          this.$toast("短信发送失败")
        })
    },
    //点击退出
    exit() {
      this.isShowExitView = true
    },
    //提现
    withdrawa(symbol) {
      this.$router.push({
        path: "/Withdrawal",
        query: {
          symbol: symbol,
        },
      })
    },
    //去交易记录
    gotoRecordList(symbol) {
      if (symbol != "") {
        this.$router.push({
          path: "/MentionRecord",
          query: {
            symbol: symbol,
          },
        })
      } else {
        this.$router.push({
          path: "/electricity",
          // query: {
          //   symbol: symbol
          // }
        })
      }
    },
    //确定退出
    sure() {
      localStorage.removeItem("token")
      this.$router.push("/login")
      this.isShowExitView = false
    },
    //取消退出
    cancel() {
      this.isShowExitView = false
    },
  },
}
</script>
<style scoped lang="scss">
.help {
  width: 100%;
  background: #f9f9f9;
  padding: 20px;
  text-align: left;
  .help_title {
    font-family: Alibaba-PuHuiTi, Alibaba-PuHuiTi;
    font-weight: 700;
    font-size: 20px;
    color: #333333;
    margin-bottom: 11px;
  }
  .quest_box {
    width: 100%;
    padding: 20px 16px;
    background: #ffffff;
    border-radius: 8px;
    .quest_title {
      font-family: Alibaba-PuHuiTi, Alibaba-PuHuiTi;
      font-weight: normal;
      font-size: 16px;
      color: #333333;
      line-height: 22px;
    }
    .quest_content {
      font-family: Alibaba-PuHuiTi, Alibaba-PuHuiTi;
      font-weight: normal;
      font-size: 14px;
      color: #333333;
      line-height: 20px;
      margin-top: 16px;
      margin-bottom: 20px;
      padding-bottom: 20px;
      border-bottom: 1px solid #ededed;
    }
  }
}
</style>
